<template>
  <div style="margin-top:84px">
    <drop-detail :activity-no="activityNo" />
  </div>
</template>

<script>
import DropDetail from '../components/DropDetail.vue'
export default {
  components: { DropDetail },
  data() {
    return {
      activityNo: ''
    }
  },
  created() {
    this.activityNo = this.$route.params.id
  },
  mounted() {
  }
}
</script>
