<template>
  <div>
    <v-overlay :opacity="0.4" :value="overlay">
      <v-progress-circular indeterminate size="64" />
    </v-overlay>
    <div v-if="!overlay">
      <!-- drop 封面 -->
      <v-row no-gutters justify="center">
        <v-col cols="12">
          <v-img :src="dropData.bgImage" height="281px">
            <!-- brand logo -->
            <v-row justify="space-around" style="margin-top: 54px" no-gutters>
              <v-col cols="12">
                <v-card
                  tile
                  max-height="80px"
                  max-width="80px"
                  class="rounded-circle mx-auto px-auto"
                >
                  <v-img
                    :src="dropData.brandLogo"
                    class="rounded-circle"
                    max-height="80px"
                    max-width="80px"
                    aspect-ratio="1"
                  />
                </v-card>
              </v-col>
            </v-row>
            <!-- 品牌名称 -->
            <v-row no-gutters justify="center" style="margin-top: -3px">
              <v-col cols="12" class="text-center">
                <span class="brand-title">{{ brand_title }}</span>
              </v-col>
            </v-row>
          </v-img>
        </v-col>
      </v-row>
      <!-- 标题 -->
      <v-row justify="center" no-gutters style="margin-top: 48px">
        <v-col cols="6" class="text-center" style="max-width: 754px">
          <span class="drop-title">{{ drop_title }}</span>
        </v-col>
      </v-row>
      <!-- 发售时间 -->
      <v-row justify="center" no-gutters style="margin-top: 20px">
        <v-col class="text-center">
          <div class="sell-time-bg">
            <span class="sell-time-text" v-if="dropData.planEndTime">{{
              $t("drop.sell_time_label") +
              ":&nbsp;&nbsp;" +
              $d(new Date(dropData.planStartTime), "long") + "&nbsp;—&nbsp;" + $d(new Date(dropData.planEndTime), "long")
            }}</span>
            <span class="sell-time-text" v-else >{{              
              $t("drop.sell_time_label") +
              ":&nbsp;&nbsp;" +
              $d(new Date(dropData.planStartTime), "long")
            }}</span>
          </div>
        </v-col>
      </v-row>
      <!-- drop白名单 -->
      <v-row
        justify="center"
        no-gutters
        style="margin-top: 14px"
        v-if="whiteListShow == '1'"
      >
        <v-col class="text-center" align-self="center">
          <div class="flexCenter">
            <div class="whiteListButton">
              <span class="whiteListFont">{{ $t("drop.white_list") }}</span>
            </div>
            <div class="whiteListDescription">
              {{ $t("drop.white_list_description") }}
            </div>
          </div>
        </v-col>
      </v-row>
      <!-- drop描述 -->
      <v-row no-gutters justify="center" style="margin-top: 30px">
        <v-col cols="12" style="max-width: 940px">
          <div class="drop-desc text-pre-wrap">
            {{ drop_desc }}
          </div>
        </v-col>
      </v-row>

      <v-row no-gutters justify="center">
        <v-col>
          <v-card max-width="1168px" flat class="mx-auto mt-15 mb-8">
            <!-- plus 筛选 -->
            <v-row v-if="plus_list.length > 0" no-gutters>
              <v-col
                style="height: 39px"
                class="d-flex justify-start align-end"
              >
                <div class="plus-title d-inline-block">Plus</div>
                <v-tooltip right>
                  <template #activator="{ on, attrs }">
                    <div class="d-inline-block ml-2" v-bind="attrs" v-on="on">
                      <v-img
                        :src="require('@/assets/icon/note@3x.png')"
                        height="24"
                        width="24"
                        style="margin-bottom: 4px"
                      />
                    </div>
                  </template>
                  <span style="white-space: pre-line">{{
                    $t("drop.plus_tips")
                  }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <!-- plus 筛选按钮 -->
            <v-chip-group
              v-if="plus_list.length > 0"
              v-model="plus_filters"
              column
              class="plus-groups"
              multiple
            >
              <v-chip
                color="black"
                class="plus-chip-all"
                active-class="plus-active-all"
              >
                <span class="plus-chip-text">{{ $t("drop.plus_all") }}</span>
              </v-chip>
              <v-chip
                v-for="item in plus_list"
                :key="item.id"
                :color="item.bg_color"
                active-class="plus-active"
              >
                <span class="plus-chip-text">{{
                  en ? item.name_en : item.name
                }}</span>
              </v-chip>
            </v-chip-group>
            <!-- 商品展示 -->
            <!-- :to="'/drop/activity/' + item.skc_code" -->
            <!-- <v-col v-for="item in filter_items" :key="item.skc_code" cols="3"> -->
            <v-row no-gutters style="margin-top: 29px">
              <v-col v-for="item in filter_items" :key="item.activityNo" cols="3">
                <v-card
                  flat
                  tile
                  :to="'/drop/activity/' + item.activityNo"
                  max-width="292px"
                  class="goods-card"
                  hover
                >
                  <v-card-text class="pt-3 pb-4">
                    <v-img
                      :src="item.cover"
                      max-width="268px"
                      max-height="268px"
                      aspect-ratio="1"
                      class="mx-3"
                      style="border-radius: 12px"
                    />
                    <p class="goods-name mt-3 ml-5">
                      {{ en ? item.name_en : item.name }}
                    </p>
                    <!-- 价格等级 -->
                    <v-row no-gutters justify="center" class="mt-3">
                      <v-col cols="6" class="ml-5" align-self="center">
                        <p class="good-price" style="margin-top: 2px">
                          {{ parseFloat(item.price) + " " + item.currency }}
                        </p>
                      </v-col>
                      <v-spacer />
                      <v-col
                        class="d-flex justify-end mr-5"
                        align-self="center"
                      >
                        <v-img
                          v-if="item.level"
                          max-width="87px"
                          max-height="19px"
                          :src="
                            require('@/assets/icon/level_' +
                              item.level.toLowerCase() +
                              '@3x.png')
                          "
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <!-- 品牌 -->
            <v-row no-gutters style="margin-top: 69px" justify="center">
              <v-col cols="12" align-self="center">
                <div class="brand-divider" />
              </v-col>
            </v-row>
            <v-row
              no-gutters
              style="margin-top: 30px; margin-left: 12px"
              justify="center"
            >
              <v-col cols="1" align-self="center">
                <v-img
                  :src="dropData.brandLogo"
                  class="brand-logo-border rounded-circle"
                  max-height="60px"
                  max-width="60px"
                />
              </v-col>
              <v-col cols="10" class="ml-5" style="max-width: 996px">
                <span class="brand-title-bottom d-block">
                  {{ brand_title }}
                </span>
                <span class="brand-desc" style="margin-top: 5px">
                  {{ brand_desc }}
                </span>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import { getDropDetail } from "@/api/drop.js";
import { getIntersect } from "@/utils";
export default {
  name: "DropDetailxxx",
  props: {
    activityNo: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      overlay: true,
      drop_title: "",
      drop_desc: "",
      brand_title: "",
      brand_desc: "",
      dropData: null,
      brandData: null,
      en: this.$i18n.locale === "en",
      plus_list: [],
      plus_filters: [],
      filter_items: [],
      whiteListShow: 0,
    };
  },
  watch: {
    plus_filters(n, o) {
      //   console.log(n, o)
      if (o.indexOf(0) === -1) {
        if (n.indexOf(0) >= 0) {
          // 使用了全选
          for (let i = 0; i <= this.plus_list.length; i++) {
            if (n.indexOf(i) === -1) {
              n.push(i);
            }
          }
        } else {
          if (n.length > 0 && n.length === this.plus_list.length) {
            // 其他选项全部选完
            n.push(0);
          }
        }
      } else {
        if (n.indexOf(0) === -1) {
          // 取消全选
          n.splice(0);
        } else {
          if (n.length <= this.plus_list.length) {
            const idx = n.indexOf(0);
            n.splice(idx, 1);
          }
        }
      }
      if (n.length === 0 || n.indexOf(0) >= 0) {
        if (this.filter_items.length !== this.dropData.items) {
          this.filter_items.splice(0);
          this.dropData.items.forEach((element) => {
            this.filter_items.push(element);
          });
        }
      } else {
        const filter_plusids = [];
        n.forEach((element) => {
          if (element > 0) {
            const item = this.plus_list[element - 1];
            const plusid = item.id;
            if (filter_plusids.indexOf(plusid) === -1) {
              filter_plusids.push(plusid);
            }
          }
        });
        this.filter_items.splice(0);
        this.dropData.items.forEach((element) => {
          const arr = getIntersect(filter_plusids, element.plus);
          if (arr && arr.length > 0) {
            this.filter_items.push(element);
          }
        });
      }
    },
    "$i18n.locale"(n) {
      this.changeLang(n);
      this.en = n === "en";
    },
  },
  created() {
    getDropDetail(this.activityNo)
      .then((resp) => {
        if (resp.code === 0) {
          this.whiteListShow = resp.data.whiteListShow;
          this.dropData = resp.data;
          this.brandData = resp.data.brandInfo;
          this.plus_list = resp.data.plus_list;
          this.changeLang(this.$i18n.locale);
          this.dropData.items.forEach((element) => {
            console.log('dropData.items element: '+ JSON.stringify(element))
            this.filter_items.push(element);
          });
          this.overlay = false;
        } else if (resp.code === "SAS070") {
          this.$router.replace({ path: "/404" });
        } else {
          console.error(resp);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  },
  mounted() {},
  methods: {
    changeLang(lang) {
      if (lang === "en") {
        this.drop_title = this.dropData.titleEn;
        this.drop_desc = this.dropData.contentEn;
        this.brand_title = this.dropData.brandInfo.brand;
        this.brand_desc = this.dropData.brandInfo.description;
      } else if (lang === "zh") {
        this.drop_title = this.dropData.title;
        this.drop_desc = this.dropData.content;
        this.brand_title = this.dropData.brandInfo.brandCn;
        this.brand_desc = this.dropData.brandInfo.descriptionCn;
      }
    },
  },
};
</script>

<style scoped>
.brand-logo-border {
  border: 1px solid #e9e9e9;
}
.brand-title {
  font-size: 32px;
  font-weight: bold;
  color: #ffffff;
  line-height: 75px;
  text-shadow: 0px 4px 12px rgba(0, 0, 0, 0.5);
}
.drop-title {
  font-size: 42px;
  font-weight: bold;
  color: #000000;
  line-height: 50px;
}
.sell-time-bg {
  height: 46px;
  width: auto;
  background: rgba(191, 255, 113, 0.3);
  border-radius: 38px;
  display: inline-block;
}
.sell-time-text {
  height: 22px;
  font-size: 16px;
  font-weight: 600;
  color: #000000;
  line-height: 19px;
  opacity: 0.8;
  margin: 12px 26px;
  display: flex;
  align-items: center;
}
.drop-desc {
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  line-height: 24px;
  opacity: 0.3;
  text-align: center;
}
.goods-name {
  height: 18px;
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  line-height: 18px;
}
.good-price {
  height: 22px;
  font-size: 16px;
  font-weight: bold;
  color: #1947e5;
  line-height: 22px;
}
.brand-divider {
  max-width: 1174px;
  width: 100%;
  height: 1px;
  border-bottom: 1px solid #eeeff4;
}
.brand-title-bottom {
  font-size: 20px;
  font-weight: 600;
  color: #000000;
  line-height: 27px;
}
.brand-desc {
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  line-height: 24px;
  opacity: 0.3;
}
.plus-title {
  height: 39px;
  font-size: 32px;
  font-weight: bold;
  color: #000000;
  line-height: 39px;
}
.plus-chip-text {
  height: 15px;
  font-size: 12px;
  font-weight: 400;
  color: #000000;
  line-height: 15px;
  padding-left: 16px;
  padding-right: 16px;
}
.plus-chip-all >>> .plus-chip-text {
  color: #ffffff !important;
}
.plus-groups >>> .v-slide-group__content {
  padding: 0;
}
.plus-active {
  box-shadow: 0px 2px 0px 0px #000000;
  border: 1.5px solid #000000 !important;
}
.plus-active-all {
  background-color: white !important;
  color: black !important;
  box-shadow: 0px 2px 0px 0px #000000;
  border: 1.5px solid #000000;
}
.plus-active-all >>> .plus-chip-text {
  color: #000000 !important;
}
.plus-groups >>> .v-chip {
  height: 30px;
  border-radius: 20px;
  margin-right: 12px;
  margin-top: 14px;
  padding: 0px;
}
.plus-groups >>> .v-chip:before {
  content: none !important;
}
.goods-card >>> .v-card__subtitle,
.v-card__text,
.v-card__title {
  padding: 0px;
}
.goods-card p {
  margin-bottom: 0px;
}

.goods-card:hover {
  box-shadow: 0px 6px 18px 0px rgba(194, 194, 194, 0.5) !important;
  border-radius: 12px !important;
}
.goods-card:hover::after {
  opacity: 1;
  position: absolute;
  left: calc(100% - 16px);
  top: calc(100% - 10px);
  content: none;
  display: inline-block;
  background: url("~@/assets/icon/Finger@3x.png") no-repeat 0 0;
  background-size: 19px 20px;
  width: 19px;
  height: 20px;
}
.whiteListDescription {
  margin-top: 10px;
  height: 17px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.3);
  line-height: 17px;
}
</style>
